<template>
    <div>
        <vs-row v-if="!hideFilters">
            <template v-if="!entity">
                <!-- Tag -->
                <vs-col vs-xs="12" vs-sm="4" vs-lg="3">
                    <SelectForeign
                        label="Filter by Tag"
                        :model.sync="filters.entity_compliance__entity__tags"
                        placeholder="Type to search"
                        :multiple="false"
                        :load_initial="true"
                        :label_keys="['name',]"
                        :show_id_in_label="false"
                        :endpoint="`${$store.state.apiendpoints.entity_tag_list}`"
                        container_class="w-full"
                        :use_id_as_value="true"
                        :list_length="100"
                    />
                </vs-col>

                <!-- Group -->
                <vs-col vs-xs="12" vs-sm="4" vs-lg="3">
                    <SelectForeign
                        label="Filter by Group"
                        :model.sync="filters.entity_compliance__entity__groups"
                        placeholder="Type to search"
                        :multiple="false"
                        :load_initial="true"
                        :label_keys="['name',]"
                        :show_id_in_label="false"
                        :endpoint="`${$store.state.apiendpoints.entity_group_list}`"
                        container_class="w-full"
                        :use_id_as_value="true"
                        :list_length="100"
                    />
                </vs-col>

                <!-- Entity -->
                <vs-col vs-xs="12" vs-sm="4" vs-lg="3">
                    <SelectForeign
                        label="Filter by Entity"
                        :model.sync="filters.entity_compliance__entity"
                        placeholder="Type to search"
                        :multiple="false"
                        :load_initial="true"
                        :label_keys="['name',]"
                        :show_id_in_label="false"
                        :endpoint="`${$store.state.apiendpoints.data_entity_list}`"
                        container_class="w-full"
                        :use_id_as_value="true"
                        :list_length="100"
                    />
                </vs-col>

            </template>

            <!-- Status -->
            <vs-col vs-xs="12" vs-sm="4" vs-lg="3">
                <InputSelect
                    label="Filter by Status"
                    placeholder="Click to Select"
                    :options="[
                        { value: 'filed', label: 'Filed' },
                        { value: 'upcoming', label: 'Upcoming' },
                        { value: 'overdue', label: 'Overdue' },
                        { value: 'not_applicable', label: 'Not Applicable' }
                    ]"
                    :model.sync="filters.filed_status"
                />
            </vs-col>

            <!-- Registration Type -->
            <vs-col vs-xs="12" vs-sm="4" vs-lg="3">
                <SelectForeign
                    label="Filter by Registration Type"
                    :model.sync="filters.compliance_date__compliance__registration_type"
                    placeholder="Type to search"
                    :multiple="false"
                    :load_initial="true"
                    :label_keys="['name',]"
                    :show_id_in_label="false"
                    :endpoint="`${$store.state.apiendpoints.registration_types}`"
                    container_class="w-full"
                    :use_id_as_value="true"
                    :list_length="100"
                />
            </vs-col>

            <!-- Registration -->
            <vs-col vs-xs="12" vs-sm="4" vs-lg="3" v-if="filters.compliance_date__compliance__registration_type">
                <SelectForeign
                    label="Filter by Registration"
                    :model.sync="filters.entity_compliance__registration"
                    placeholder="Type to search"
                    :multiple="false"
                    :load_initial="true"
                    :label_keys="['registration_identifier',]"
                    :show_id_in_label="false"
                    :endpoint="`${$store.state.apiendpoints.data_entity_registrations_raw}?status=a&registration_type=${filters.compliance_date__compliance__registration_type}`"
                    container_class="w-full"
                    :use_id_as_value="true"
                    :list_length="100"
                />
            </vs-col>

            <!-- Period -->
            <vs-col vs-xs="12" vs-sm="4" vs-lg="3">
                <div>
                    <label class="vs-input--label">Choose Period</label>
                    <datepicker
                        v-model="month_view_value"
                        placeholder="MM YYYY"
                        :minimumView="'month'"
                        :maximumView="'year'"
                        :disabled-dates="disabled_dates"
                        :typeable="true"
                        :clear-button="true"
                        format="MMM yyyy"
                    ></datepicker>
                </div>
            </vs-col>

            <!-- Compliance -->
            <vs-col vs-xs="12" vs-sm="4" vs-lg="3">
                <SelectForeign
                    :model.sync="filters.entity_compliance__compliance"
                    label="Compliance"
                    placeholder="Select Compliance"
                    :multiple="false"
                    :load_initial="true"
                    :label_keys="['name']"
                    :show_id_in_label="false"
                    :endpoint="`${$store.state.apiendpoints.data_entity_compliances}?registration_type=${filters.compliance_date__compliance__registration_type || ''}`"
                    container_class="w-full mb-3"
                    :use_id_as_value="true"
                    :list_length="200"
                />
            </vs-col>
        </vs-row>

        <vs-row class="px-0 mb-3" v-if="false">
            <vs-col vs-xs="12" vs-sm="4" vs-lg="4">
                <vx-card class="text-center">
                    <h3 :class="{'text-success': summary.filed > 0}">{{ summary.filed }}</h3>
                    <p>Filed</p>
                </vx-card>
            </vs-col>
            <vs-col vs-xs="12" vs-sm="4" vs-lg="4">
                <vx-card class="text-center">
                    <h3 :class="{'text-danger': summary.overdue > 0}">{{ summary.overdue }}</h3>
                    <p>Overdue</p>
                </vx-card>
            </vs-col>
            <vs-col vs-xs="12" vs-sm="4" vs-lg="4">
                <vx-card class="text-center">
                    <h3>{{ summary.upcoming }}</h3>
                    <p>Upcoming</p>
                </vx-card>
            </vs-col>
        </vs-row>
        <vs-row>
            <vs-col :class="{'px-0': viewMode == 'compact'}">
                <vs-table :data="calendar" class="table-fix">
                    <template slot="thead">
                        <template v-if="viewMode == 'compact'">
                            <th>Compliance</th>
                            <th>Status</th>
                            <th></th>
                        </template>
                        <template v-else>
                            <th v-if="!entity">Entity</th>
                            <th>Registration</th>
                            <th>Compliance</th>
                            <th>Period</th>
                            <th>Status</th>
                            <th></th>
                        </template>
                    </template>

                    <template v-for="(item, index) in calendar">
                        <tr v-if="viewMode == 'compact'" :key="'compact-' + index">
                            <td>
                                <strong>
                                  {{ item.entity_compliance.compliance.name }}
                                </strong>
                                <small class="block">
                                  <strong class="text-primary">
                                    {{ item.entity_compliance.registration.registration_type.name }}:
                                  </strong>
                                  {{ item.entity_compliance.registration.registration_identifier }}
                                </small>
                                <small class="block" v-if="!entity">
                                    <strong class="text-primary">
                                        Entity:
                                    </strong>
                                    {{ item.entity_compliance.entity.name }}
                                </small>
                            </td>
                            <td>
                                <template v-if="!item.is_applicable">
                                    <font-awesome-icon :icon="cancelIcon" class="text-dark mr-1" /> Not Applicable
                                </template>
                                <template v-else-if="item.is_filed">
                                    <font-awesome-icon :icon="checkIcon" class="text-success mr-1" /> Filed
                                    <small class="block" v-if="item.filing_status">
                                        <strong class="text-primary">Filed On:</strong> <FormatText :text="item.filing_status.filing_date" format="date" />
                                    </small>
                                </template>
                                <template v-else>
                                    <template v-if="item.due_in_days > 0">
                                        <font-awesome-icon :icon="checkIcon" class="text-warning mr-1" /> Due in {{ item.due_in_days }} Days
                                    </template>
                                    <template v-else-if="item.due_in_days < 0">
                                        <font-awesome-icon :icon="crossIcon" class="text-danger mr-1" /> Late by {{ Math.abs(item.due_in_days) }} Days
                                    </template>
                                    <template v-else-if="item.due_in_days == 0">
                                        <font-awesome-icon :icon="crossIcon" class="text-warning mr-1" /> Due Today
                                    </template>
                                    <small class="block">
                                        <strong class="text-primary">Due On:</strong> <FormatText :text="item.compliance_date.extended_date" format="date" />
                                    </small>
                                </template>
                            </td>
                            <td>
                                <template v-if="!item.is_applicable">
                                    <vs-button @click="performAction(index, 'mark_applicable')" size="small" color="danger" type="border">
                                        Mark Applicable
                                    </vs-button>
                                </template>
                                <template v-else>
                                    <template v-if="item.is_filed">
                                        <vs-button @click="performAction(index, 'mark_unfiled')" size="small" color="danger" type="border">
                                            Unfile
                                        </vs-button>
                                    </template>
                                    <template v-else>
                                        <vs-button @click="performAction(index, 'mark_filed')" size="small" color="success" type="border" class="px-1">
                                            File
                                        </vs-button>
                                        <vs-button class="ml-2 px-1" @click="performAction(index, 'mark_not_applicable')" size="small" color="warning" type="border">
                                            N/A
                                        </vs-button>
                                    </template>
                                </template>
                            </td>
                        </tr>
                        <tr :key="index" v-else>
                            <td v-if="!entity" class="py-2">
                                <router-link :to="{name:'view-entity', params: {id: item.entity_compliance.entity.id}}">
                                    {{ item.entity_compliance.entity.name }}
                                </router-link>
                                <small class="block">
                                    <strong class="text-primary">Legully ID:</strong> {{ item.entity_compliance.entity.id }}
                                </small>
                            </td>
                            <td class="py-2">
                                {{ item.entity_compliance.registration.registration_identifier }}
                                <small class="block">
                                    <strong class="text-primary">Type:</strong> {{ item.entity_compliance.registration.registration_type.name }}
                                </small>
                            </td>
                            <td class="py-2">
                                {{ item.entity_compliance.compliance.name }}
                            </td>
                            <td class="py-2">
                                {{ item.compliance_date.period_month }}/{{ item.compliance_date.period_year }}
                                <small class="block">
                                    <strong class="text-primary">Frequency:</strong> <FormatText format="select" :text="item.compliance_date.period_type" :kwargs="period_type" />
                                </small>
                            </td>
                            <td class="py-2">
                                <template v-if="!item.is_applicable">
                                    <font-awesome-icon :icon="cancelIcon" class="text-dark mr-1" /> Not Applicable
                                </template>
                                <template v-else-if="item.is_filed">
                                    <font-awesome-icon :icon="checkIcon" class="text-success mr-1" /> Filed
                                    <small class="block" v-if="item.filing_status">
                                        <strong class="text-primary">Filed On:</strong> <FormatText :text="item.filing_status.filing_date" format="date" />
                                    </small>
                                </template>
                                <template v-else>
                                    <template v-if="item.due_in_days > 0">
                                        <font-awesome-icon :icon="checkIcon" class="text-warning mr-1" /> Due in {{ item.due_in_days }} Days
                                    </template>
                                    <template v-else-if="item.due_in_days < 0">
                                        <font-awesome-icon :icon="crossIcon" class="text-danger mr-1" /> Late by {{ Math.abs(item.due_in_days) }} Days
                                    </template>
                                    <template v-else-if="item.due_in_days == 0">
                                        <font-awesome-icon :icon="crossIcon" class="text-warning mr-1" /> Due Today
                                    </template>
                                    <small class="block">
                                        <strong class="text-primary">Due On:</strong> <FormatText :text="item.compliance_date.extended_date" format="date" />
                                    </small>
                                </template>
                            </td>
                            <td class="text-right">
                                <template v-if="!item.is_applicable">
                                    <vs-button @click="performAction(index, 'mark_applicable')" size="small" color="danger" type="border">
                                        Mark Applicable
                                    </vs-button>
                                </template>
                                <template v-else>
                                    <template v-if="item.is_filed">
                                        <vs-button @click="performAction(index, 'mark_unfiled')" size="small" color="danger" type="border">
                                            Unfile
                                        </vs-button>
                                    </template>
                                    <template v-else>
                                        <vs-button @click="performAction(index, 'mark_filed')" size="small" color="success" type="border">
                                            File
                                        </vs-button>
                                        <vs-button class="ml-2" @click="performAction(index, 'mark_not_applicable')" size="small" color="warning" type="border">
                                            Not Applicable
                                        </vs-button>
                                    </template>
                                </template>
                            </td>
                        </tr>
                    </template>
                </vs-table>

                <template v-if="!disablePagination">
                    <vs-row v-if="total_results" class="mt-3">
                        <vs-pagination
                            :total="total_pages"
                            v-model="current_page"
                            goto
                            class="mx-auto"
                        />
                    </vs-row>
                </template>
            </vs-col>
        </vs-row>
    </div>
</template>

<script>
import { faCheckCircle, faSpinner, faStopCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import Datepicker from "vuejs-datepicker";
import moment from "moment"

export default {
    props: {
        entity: {
            required: false,
        },
        embedded_mode: {
            required: false,
            default: false,
        },
        embedded_filters: {
            required: false,
            default: () => { return {} },
        },
        disablePagination: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideFilters: {
            type: Boolean,
            default: false,
            required: false
        },
        viewMode: {
            type: String,
            default: 'default',
            required: false
        }
    },
    components: {
        Datepicker,
    },
    data() {

        let offset = parseInt(this.$route.query.offset || 0);
        let limit = parseInt(this.$route.query.limit || 20);
        let today = new Date(Date.now());
        let current_page = Math.ceil((offset + limit) / limit);

        return {
            minimum_date: null,

            disabled_dates: {
                from: today,
                to: this.minimum_date,
            },

            calendar: [],
            period_type: [
                {value: 'q', label: "Quarterly"},
                {value: 'm', label: "Monthly"},
                {value: 's', label: "Semi-Annually"},
                {value: 'a', label: "Annually"},
            ],
            month_view_value: null,

            popup: {
                enabled: false,
                info: null,
            },
            summary: {
                filed: 0,
                upcoming: 0,
                overdue: 0,
            },

            popup_content: [],
            //variables for month view

            next_url: null,
            total_results: null,
            current_page: current_page,

            filters: {
                ordering: this.$route.query.ordering || [],
                offset: offset,
                limit: limit,

                entity: this.entity ? this.entity.id : null,
                filed_status: this.$route.query.filed_status || null,

                range_from: this.$route.query.range_from || null,
                range_to: this.$route.query.range_to || null,

                entity_compliance__entity__tags: this.$route.query.entity_compliance__entity__tags || null,
                entity_compliance__entity__groups: this.$route.query.entity_compliance__entity__groups || null,
                entity_compliance__entity: this.$route.query.entity_compliance__entity || null,
                compliance_date__compliance__registration_type: this.$route.query.compliance_date__compliance__registration_type || null,
                entity_compliance__registration: this.$route.query.entity_compliance__registration || null,
                entity_compliance__compliance: this.$route.query.entity_compliance__compliance || null,
                compliance_date__period_month: this.$route.query.compliance_date__period_month || null,
                compliance_date__period_year: this.$route.query.compliance_date__period_year || null,

            },
        };
    },
    computed: {
        SpinnerIcon: () => faSpinner,
        crossIcon: () => faTimesCircle,
        checkIcon: () =>  faCheckCircle,
        cancelIcon: () => faStopCircle,
        total_pages() {
            if (this.total_results == 0 || this.filters.limit == 0) return 1;
            return Math.ceil(this.total_results / this.filters.limit);
        },
    },
    mounted() {
        this.timer = null;
        this.init();
    },
    watch: {
        filters: {
            deep: true,
            handler(newV) {
                if (this.current_page == null) return;

                if (this.timer) clearTimeout(this.timer);

                this.timer = setTimeout(() => {
                this.$router.push({
                    name: this.$route.name,
                    query: newV,
                });
                }, 1200);
            },
        },
        "$route.query"(newV) {
            this.load_data(newV);
        },
        current_page(newV, oldV) {
            if (newV == oldV) return;
            this.filters.offset = (newV - 1) * this.filters.limit;
        },
        month_view_value() {
            if (!this.month_view_value) {
                this.filters.compliance_date__period_month = null
                this.filters.compliance_date__period_year = null
            } else {
                let parsed_date = moment(this.month_view_value, "MMM YYYY")
                this.filters.compliance_date__period_month = parsed_date.month() + 1
                this.filters.compliance_date__period_year = parsed_date.year()
            }
        }
    },
    methods: {
        init() {
            this.load_data(this.filters);
        },
        calculate_page() {
            if (this.filters.offset == 0) this.current_page = 1;
            else
                this.current_page = Math.ceil(this.filters.offset / this.filters.limit);
        },
        load_data(filters = null, next = false) {
            if (!this.embedded_mode) this.$vs.loading();

            if (next && !this.next_url && !filters) return;

            let url = next && !filters
                ? this.next_url
                : this.$store.state.apiendpoints.data_entity_compliancecalendar;

            let payload = this.deepclone(filters);

            if (this.embedded_mode && this.embedded_filters) {
                payload = { ...payload, ...this.embedded_filters };
            }

            this.$api(this, "GET", url, payload).then(
                (res) => {
                    this.calendar = this.calculateDueIn(res.results)
                    this.total_results = res.count
                    this.next_url = res.next

                    if (!this.embedded_mode) this.$vs.loading.close();
                },
                (err) => {
                    if (!this.embedded_mode) this.$vs.loading.close();
                    this.$vs.notify({
                        time: 2500,
                        title: "Error",
                        text: "An unknown error occured while loading calendar. Please try again.",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        color: "danger",
                    });
                }
            );
        },
        calculateDueIn(data) {
            let today = moment()
            return data.map(v => {
                let due_date = moment(v.compliance_date.extended_date)

                v['due_in_days'] = due_date.diff(today, 'days')
                return v
            })
        },
        performAction(index, action) {
            let msg = {
                'mark_filed': "mark item as filed",
                'mark_unfiled': "mark item as not filed",
                'mark_applicable': "mark item as applicable",
                'mark_not_applicable': "mark item as not applicable",
            }

            this.$vs.dialog({
                type: 'confirm',
                color: 'primary',
                title: `Are you sure?`,
                text: `Are you sure you want to ${msg[action]}?`,
                acceptText: 'Yes',
                cancelText: 'Go Back',
                accept: () => this.doAction(index, action),
                cancel: () => null
            })

        },
        doAction(index, action) {
            let item = this.calendar[index]
            if (!item) return

            let payload = {action}

            let url = this.sprintf(
                this.$store.state.apiendpoints.data_entity_compliancecalendar_action,
                item.id
            )

            this.$vs.loading()

            this.$api(this, 'POST', url, null, payload).then(
              res => {
                    this.$vs.loading.close()
                    this.init()

                    this.$vs.notify({
                        time: 2500,
                        title: "Success",
                        text: "Action completed successfully.",
                        iconPack: "feather",
                        icon: "icon-check-circle",
                        color: "success",
                    });
                },
                err => {
                    this.$vs.loading.close()
                    this.$vs.notify({
                        time: 2500,
                        title: "Error",
                        text: "An unknown error occurred while performing action. Please try again.",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        color: "danger",
                    });
                }
            )

        }
    },
};
</script>

<style>
    .table-fix .vs-table--tbody {
        z-index: 1;
    }
</style>
